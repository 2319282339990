$extra-large: 1200px;


.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: black;
    color: white;
    height: 70px;
    @media (min-width: 360px) and (max-width: 500px) { 
        height: auto;
    } 
    @media (min-width: 501px) and (max-width: 1199px) { 
        height: auto;
    } 
    .footer {
        width: $extra-large;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (min-width: 360px) and (max-width: 500px) { 
            width: 360px;
            padding: 0px;
        } 
        @media (min-width: 501px) and (max-width: 1199px) { 
            width: 500px;
            padding: 0px;
        } 
        .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            display: none;

            .left {
                font-size: 4rem;
            }

            .right {
                font-size: 1.875rem;
                cursor: pointer;

                &:hover {
                    color: whitesmoke;
                }
            }
        }

        .center {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 40px;
            margin-top: 4rem;
            display: none;

            .row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .left {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .solutions {
                        padding: 0.5rem;
                        background-color: black;
                        color: white;
                        margin-right: 1rem;
                        font: {
                            size: 0.875rem;
                            weight: bold;
                        }
                    }

                    .btn-grp {
                        display: flex;
                        flex-direction: row;

                        .btn {
                            border: none;
                            background-color: black;
                            color: white;
                            padding: 0.5rem;
                            font-size: 0.813rem;
                            cursor: pointer;

                            &:hover {
                                color: whitesmoke;
                            }
                        }
                    }
                }

                .left {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    
                }

                .right {
                    display: flex;
                    flex-direction: row;
                    padding: 1rem;
                    background-color: black;
                    color: white;
                    align-items: center;

                    @mixin btn-default {
                        padding: 1rem;
                        cursor: pointer;
                        font-weight: 400;
                        font-size: 0.813rem;
                        margin-right: 1rem;
                        color: #bdbdbd;
                    }

                    @mixin btn-active {
                        font-weight: 900;
                        color: whitesmoke;
                    }

                    .btn-grp {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .btn {
                            @include btn-default;

                            &:hover {
                                @include btn-active;
                            }

                            &:active {
                                @include btn-active;
                            }
                        }

                        .btn-active {
                            @include btn-default;
                            @include btn-active;
                        }
                    }

                    .privacy {
                        cursor: pointer;

                        &:hover {
                            color: whitesmoke;
                        }
                    }
                }
            }
        }

        .bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                @media (min-width: 360px) and (max-width: 500px) { 
                    flex-wrap: wrap;
                    align-items: flex-start;
                } 
                @media (min-width: 501px) and (max-width: 1199px) { 
                    flex-wrap: wrap;
                    align-items: flex-start;

                } 
                .left {
                    display: flex;
                    @media (min-width: 360px) and (max-width: 500px) { 
                        margin-top: 1rem;
                    } 
                    @media (min-width: 501px) and (max-width: 1199px) { 
                        margin-top: 1rem;
                    } 
                    .about {
                        padding: 0.5rem;
                        padding-top: 0.4rem;
                        background-color: black;
                        color: white;
                        margin-right: 1rem;
                        font: {
                            size: 0.875rem;
                            weight: bold;
                        }
                        @media (min-width: 360px) and (max-width: 500px) { 
                            
                        } 
                        @media (min-width: 501px) and (max-width: 1199px) { 
                            padding-top: 0.4rem;
                        } 
                    }

                    .btn-grp {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        @media (min-width: 360px) and (max-width: 500px) { 
                            display: flex;
                            flex-wrap: wrap;
                        } 
                        @media (min-width: 501px) and (max-width: 1199px) { 
                            display: flex;
                            flex-wrap: wrap;
                        } 
                        .first-container {
                            display: flex;
                            flex-direction: row;
                            @media (min-width: 360px) and (max-width: 500px) { 
                                width: 100%; 
                            } 
                            @media (min-width: 501px) and (max-width: 1199px) { 
                                width: 100%; 
                            } 
                            a {
                                text-decoration:none; 
                                color:#F8F8F8;
                                display: flex;
                            }
                            .first-btn {
                                border: none;
                                background-color: black;
                                color: #F8F8F8;
                                padding: 0.5rem;
                                font-size: 0.813rem;
                                cursor: pointer;
                                text-decoration-line: none;
                                &:hover {
                                    color: whitesmoke;
                                }
                            }
                        }
                        .second-container {
                            display: flex;
                            flex-direction: row;
                            @media (min-width: 360px) and (max-width: 500px) { 
                                width: 100%; 
                            } 
                            @media (min-width: 501px) and (max-width: 1199px) { 
                                width: 100%; 
                            } 
                            a {
                                text-decoration:none; 
                                color:#F8F8F8;
                            }
                            .second-btn {
                                border: none;
                                background-color: black;
                                color: #F8F8F8;
                                padding: 0.5rem;
                                font-size: 0.813rem;
                                cursor: pointer;
                                &:hover {
                                    color: whitesmoke;
                                }
                            }
                        }
                    }
                }  
                .right {
                    display: flex;
                    flex-direction: row;
                    padding: 1rem;
                    background-color: black;
                    color: white;
                    align-items: center;
                    @media (min-width: 360px) and (max-width: 500px) { 
                        justify-content: flex-start;
                        width: 100%;
                        padding-right: 0rem;
                    } 
                    @media (min-width: 501px) and (max-width: 1199px) { 
                        justify-content: flex-start;
                        width: 100%;
                    } 
                }
            }
        }  
    }
}