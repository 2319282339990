$extra-large: 1200px;
a {
    text-decoration: none; 
    color: #F8F8F8;
}
.header-container {
    &.true {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        z-index: 1;
        transition: all .3s ease;
    }

    &.false {
        opacity: 0;
        height: 0;
        padding: 0;
        transition: all .3s ease;
    }

    .header {
        width: 100%;
        position: fixed;
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: $extra-large;
            padding-left: 20px;
            padding-right: 20px;
            align-items: center;
            @media (min-width: 360px) and (max-width: 500px) { 
                align-items: center;
                width: 360px;
            } 
            @media (min-width: 501px) and (max-width: 1199px) { 
                align-items: center;
                width: 500px;
            } 
            .logo {
                width: 92.35px;
                height: 36px;
                cursor: pointer;
                @media (min-width: 360px) and (max-width: 500px) { 
                    width: 71.83px;
                    height: 28px;
                } 
                @media (min-width: 501px) and (max-width: 1199px) { 
                    width: 71.83px;
                    height: 28px;
                } 
            }

            nav {
                display: flex;
                align-items: center;
                flex-direction: row;
                .btn-grp {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @mixin btn-base {
                        padding: 10px;
                        background-color: white;
                        border: none;
                        height: 45px;
                        line-height: 1.7rem;
                        margin-right: 1rem;
                        vertical-align: center;
                        cursor: pointer;
                        font: {
                            size: 1rem;
                            weight: bold;
                        }
                        &:hover {
                            background-color: black;
                            color: white;
                            border-radius: 50px;
                        }
                    }
                    .btn-about {
                        display: none;
                        width: 83px;
                        @include btn-base();
                    }

                    .btn-solutions {
                        display: none;
                        width: 123px;
                        @include btn-base();
                    }

                    .btn-contact {
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        padding: 10px;
                        width: 178px;
                        height: 28px;
                        border: 1px solid white;
                        line-height: 1.5rem;
                        background-color: black;
                        color: #F8F8F8;
                        border-radius: 1000px;
                        cursor: pointer;
                        font: {
                            size: 1rem;
                            weight: bold;
                        }
                        @media (min-width: 360px) and (max-width: 500px) { 
                            width: 128px;
                        } 
                        @media (min-width: 501px) and (max-width: 1199px) { 
                            width: 128px;
                        } 
                        &:hover {
                            background-color: white;
                            color: black;
                            border-radius: 1000px;
                            border: 1px solid #000000;
                            box-shadow: 4px 4px 0px #000000;
                        }
                    }
                }
            }
        }
    }
}
